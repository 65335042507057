.trackscene {
    background-color: #FDC209;
}

.scenetracks {
    padding-left: 8px;
    padding-top: 8px;
}

#track-editor-header {
    height: 40px;
    background-color: #FDC209;
    padding-left: 16px;
    padding-top: 4px;
    font-size: 20px;
}
/* slider */
.icon-wrapper {
    position: relative;
    padding: 0px 12px;
    margin-top: -6px;
}

.icon-wrapper .anticon {
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    line-height: 1;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.25);
}

.icon-wrapper .anticon:first-child {
    left: 0;
}

.icon-wrapper .anticon:last-child {
    right: 0;
}