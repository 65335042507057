.sceneblock {
    height: 250px;
    width: 210px;
    border-style: solid;
    border-width: 1px;
    background-color: #ffffff;
    padding: 8px;
    margin-left: 8px;
}

.canvas-preview {
    /* height: 108px; */
    width: 192px;
    border-style: solid;
    border-width: 2px;
    border-color: #D8D8D8;
    z-index: 8;
}

.script-preview {
    margin-top: 8px;
    height: 70px;
    width: 192px;
    overflow-x: hidden;
    overflow-y: auto;
}


.scene-handle {
    margin-top: 5px;
    margin-left: 105px;
    height: 30px;
    width: 192px;
    overflow-x: hidden;
    overflow-y: hidden;
}