.gifcard {
    display:table-cell;  
    line-height:68px;
    height: 68px;
    width:88px;
    border-style: solid;
    border-color: #D8D8D8;
    border-width: 1px;
    border-radius: 4px;
    padding: 5px;
}

img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    align-content: middle;
}