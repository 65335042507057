.animation-list-container {
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0px 0px;
    height: 195px;
}

.animation-plan-container {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 0px;
    height: 204px;
}

.animation-card {
    /* 覆盖通用animationcard样式 */
    background-color: #edededcc;
    text-align: center;
    /* width: 60px; */
    height: 20px;
    /* display:inline;  */
    padding-left: 2px;
    max-width:100px;
    /* text-align: middle; */
    /* border-style:solid;
    border-width:1px; */
    box-shadow: 2px 2px 2px #dddddd; 
}

.animation-step {
    background-color: #edededcc;
    margin-left: 10px;
    width: 350px;
    height: 36px;
    text-align: left;
    border: none;
    /* border-style:solid;
    border-width:1px; */
    /* box-shadow: 2px 2px 2px #dddddd; */
}