.timeline-bar {
    height: 24px;
    background-color: #d8d8d8;
}

.play-controller {
    background-color: #fff;
    height: 23px;
    width: 200px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #d8d8d8
}

.timeline-ruler {
    /* background-color: #fff; */
    overflow: hidden;
}

#triangle-down {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid red;
}