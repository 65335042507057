.headerbar {
    background: #FDC209;
    height: 60px;
    padding: 16px;
    font-size: 20px;
    position: relative;
    z-index: 5;
    min-width: 785px;
}

.recordConfirm {
    text-align: center;
}

#spin {
    margin: 20px;
}
.ant-upload.ant-upload-select.ant-upload-select-text{
    float:right;
}