.defaulttab {
    /* height: 400px; */
    overflow-x: hidden;
    overflow-y: hidden;
    padding:10px 10px 0px 10px; 
    border-style:solid;
    border-width:1px;
    border-left-color: #e0e0e0;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
} 

.user-upload-list {
    padding:10px 0px 0px 0px; 
    overflow: auto;
    height: 380px;
}

.collaspe-panel {
    /* background-color: #f7f7f7; */
    border-style:none;
    overflow: auto;
}