.imagetab {
    overflow-x: hidden;
    overflow-y: hidden;
    /* height: 500px; */
    padding:10px 10px 0px 10px; 
    z-index: 5;
}

.collaspe{
    /* 仅list滚动视野太小，应整个collaspe滚动 */
    /* overflow-x: hidden;
    overflow-y: visible; */
    /* height: 475px;  */
    border-style: none;
    overflow: auto;
}

