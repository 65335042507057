.trackeditor {
    height: 250px;
    margin-left: 8px;
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #e8e8e8;
    overflow-x: auto;
    overflow-y: hidden;
}

.track-group {
    height: 204px;
    background-color: #ffffff;
    overflow-y: auto;
    overflow-x: none;
}