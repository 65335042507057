.track {
    height: 34px;
    background-color: #eeeeee;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #d8d8d8
}

.trackinfo {
    height: 34px;
    width: 200px;
    border-bottom-color: #d8d8d8;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 7px;
}

.track-header {
    height: 34px;
    background-color: #eeeeee;
    border-style: solid;
    border-width: 1px;
    border-color: #d8d8d8
}

.track-header-info {
    background-color: #ffffff;
    height: 34px;
    width: 200px;
    border-bottom-color: #d8d8d8;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 5px;
}