#addscene {
    height: 250px;
    width: 200px;
    border-style: dashed;
    border-color: #D8D8D8;
    border-width: 1px;
    background-color: #ffffff;
    margin-left: 8px;
    padding-left: 58px;
    padding-top: 76px;
}