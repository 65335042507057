.chartcard {
    height: 60px;
    border-style: solid;
    border-color: #D8D8D8;
    border-width: 1px;
    border-radius: 4px;
    padding: 3px;
}

img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}