.pane {
    background: #ffffff;
    border-style: solid;
    border-color: #ffffff;
    border-width: 1px;
    margin: 5px;
    box-shadow: 2px 2px 2px #dddddd;
}

.vega-embed summary{
    display: none !important;
}