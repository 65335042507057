#story {
margin-bottom: 0px;
}

#storyline {
    display: flex;
    padding: 8px;
    overflow-x: auto;
    overflow-y: hidden;
}

#storylineHeader {
    height: 40px;
    background-color: #FDC209;
    padding-left: 16px;
    padding-top: 4px;
    font-size: 20px;
}