.shelf {
    width: 200px;
    height: 26px;
    margin : 10px 10px 10px 10px;
    box-shadow: 2px 2px 2px #dddddd;
}

.channelName {
    width: 50px;
    height: 26px;
    background-color: rgba(55,65,92,.2);
    text-align:center;
     padding-top: 3px;  /*标签文字居中 */
}

.channelSlot {
    /* width: 190px; */
    height: 26px;
    background-color: #fff;
    padding: 3px 12px; /*标签文字居中 */
    /* text-align: left; */
    text-align:center;
}
