.card-container-tool > .ant-tabs-card > .ant-tabs-content {
    /* height: 500px; */
    margin-top: -6px;
    z-index: 5;
    width:300px;
}

.card-container-tool > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
}

.card-container-tool > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}